/* header  */
.header-container {
  min-height: 70vh;
  margin: 15vh 0;
}
.featurette-heading div {
  margin-top: 10px;
}

.header-btn {
  background-color: var(--main-primary-color);
  color: var(--background-color);
}

/* Navabar : Login  */

#circle-1 {
  height: 70px;
  width: 35px;
  border-bottom-right-radius: 70px;
  border-top-right-radius: 70px;
  background: #fafd7b;
  position: absolute;
  top: 18vh;
}

#circle-2 {
  height: 90px;
  width: 45px;
  border-bottom-left-radius: 90px;
  border-top-left-radius: 90px;
  background: #6132f8;
  position: absolute;
  top: -5vh;
  right: 0;
  z-index: 2200;
}

.arrow-box {
  position: absolute;
  top: 40%;
  right: 10%;
}

.arrow {
  width: 80%;
}

/* hero  */
.hero {
  background-color: var(--main-secondary-color);
}

/* resume  */
.resume-container .row {
  /* margin: 0 15rem; */
  flex-wrap: wrap;
  margin: 40px 15%;
  row-gap: 60px;
}
.resume-box {
  width: 200px;
  margin: 0 1%;
  position: relative;
}

.resume-box img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.selectBtnBox {
  position: absolute;
  bottom: 15%;
  right: 36%;
}

@media screen and (max-width: 992px) {
  .header-container {
    margin-top: 30vh;
    margin-bottom: 0;
  }
  .arrow {
    width: 60%;
  }
  .arrow-box {
    top: 53%;
    right: -13%;
  }
  .resume-box {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    margin-top: 40vh;
    text-align: center;
  }
  .featurette-heading {
    font-size: 2.5rem;
  }
  .bg-img-section {
    display: none;
  }
  .arrow-box {
    display: none;
  }
  .resume-container .row {
    margin: 40px 0;
  }
  .resume-box {
    width: 225px;
  }
}
