/* resume-2  */
#resume2 {
  font-family: "Noto Sans", sans-serif;
}

#left-container {
  background-color: #243e78;
  color: white;
}

#myfile {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

.resume-img {
  width: 165px;
  height: 165px;
  border-radius: 50%;
}
