@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600;700;800&display=swap");

/* utility  */
.second-heading {
  font-size: 1.3rem;
}

.third-heading {
  font-size: 0.8rem;
}

.main-heading {
  color: #2367a9;
  font-size: 2rem;
  font-weight: 600;
}

.small-text {
  font-size: 0.7rem;
}

.fa-solid {
  margin-left: 2px;
}

.bold-font {
  font-weight: 600;
}

.blue {
  color: #45b7f1;
}

.bg-white {
  background-color: #ffffff;
}

/* navbar  */

.edit-navabr {
  background-color: #ffffff;
  padding: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

/* aside  */
.edit-aside {
  background-color: #fbfbfc;
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100vh;
}

.aside-box {
  padding-top: 70px;
}

.aside-img-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.aside-img-box:hover {
  background-color: #cad5e2;
}

/* off canvaas  */
#offCanvasResume {
  width: 156vw;
}
/* off canvaas resume */
.offcanvas-style {
  position: fixed;
  left: 60px;
  height: 100%;
  z-index: 1;
  transition: 0.5s;
  padding-top: 0px;
  bottom: -59px;
}

.offcanvas-box-1 {
  min-width: 159px;
  height: 100vh;
  background-color: #ffffff;
  overflow-y: scroll;
  color: black;
  padding-top: 14px;
  padding-bottom: 50px;
}

.offcanvas-resume-box {
  width: 115px;
  margin: 20px auto;
  position: relative;
}

.offcanvas-resume-box img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.offCanvasSelectBtnBox {
  right: 29% !important;
}

.offcanvas-box-2 {
  /* width: 82vw; */
  opacity: 0.7;
  background-color: black;
  height: 100vh;
}

/* off canvaas color */
#selectcolour {
  background-color: #ffffff;
  width: 150px;
}

/* Edit container */

.edit-container {
  background-color: #f9f6fb;
  border-radius: 30px;
  width: 96vw;
  margin-top: 10vh;
  padding-left: 4vw;
}

/* .empty-box */

.empty-box {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #00000029;
  font-size: 1.3rem;
  padding: 0 6%;
}

.empty-box h5 {
  color: #d0cfd3;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
}

/* Tmplate View  */
#preview {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.checked {
  color: #ffa600;
}

/* Arrow  */

.edit-arrow-box {
  background-color: rgb(90, 32, 203);
  position: absolute;
  /* top: 0px;
  right: 0px; */
  /* width: 1vw;
  height: 22px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

/* resume 1   */

@media screen and (max-width: 575px) {
  .edit-container {
    margin-top: 13vh;
  }
  .edit-navbar-buttons {
    padding: 10px 0;
  }
  .resume1-header {
    flex-direction: column;
  }
  .skill-star,
  .language-star {
    margin-left: 10px !important;
  }
  .fa-star {
    width: 15px;
  }
}
