.login-input {
  background: #e5e7eb;
}

.modal-content {
  width: 70% !important;
}

.login-submit {
  border-radius: 8px !important;
}
