@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.lobster-font {
  font-family: "Lobster", cursive !important;
}

#resume5 h2 {
  border-bottom: 2px solid;
  padding-bottom: 10px;
}
