:root {
  --main-color: #5a20cb;
}

.resume3-heading {
  font-size: 1.5rem;
  /* margin-b */
  padding-bottom: 15px;
  /* color: var(--main-color);
  border-bottom: 3px solid var(--main-color); */
  margin-top: 15px;
}

.contact-text {
  font-size: 0.8rem;
  margin-left: 2px;
}

li {
  font-size: 0.9rem;
}

#resume3 .skill-box {
  background-color: #c6c6c6;
  margin-bottom: 15px;
  margin-right: 13px;
  padding: 0.2rem 0.9rem;
  border-radius: 8px;
}

@media screen and (max-width: 992px) {
  .contact-text {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 1.3rem;
  }
}
